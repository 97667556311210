<template>
  <section class="Contacts">
    <div class="Container">
      <h1 class="Title Contacts-Title">
        Our contacts
      </h1>
      <div class="Contacts-Content">
        <div class="Contacts-Contacts">
          <a href="mailto:office@scartesu.com" class="Contacts-Mail">office@scartesu.com</a>
          <div class="Contacts-Address">
            A.G. Leventi 5, The Leventis Gallery Tower, 13th Floor, Flat|Office 1301, 1097, Nicosia, Cyprus
          </div>
        </div>
        <div class="Contacts-Map">
          <img :src="require(`@/assets/images/${map}`)" alt="map" class="Contacts-MapMap">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactsPage",
  data() {
    return {
      map: "map.png"
    }
  }
}
</script>

<style scoped lang="scss">
.Contacts {
  padding: 54px 0;

  @media (min-width: $screen-l) {
    padding: 54px 0 107px;
  }

  &-Title {
    margin-bottom: 47px;

    @media (min-width: $screen-l) {
      margin-bottom: 107px;
      &:before {
        left: -810px;
      }
    }
  }

  &-Content {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (min-width: $screen-xl) {
      flex-direction: row;
    }
  }

  &-Contacts {
    width: 100%;
    padding: 76px 76px 76px 60px;
    background-color: var(--color-text-light);
    font-size: 25px;
    line-height: 1.69em;
    @media (min-width: $screen-xl) {
      width: 490px;
      height: 530px;
    }
  }

  &-Mail {
    margin-bottom: 17px;
    font-family: 'Lexend', sans-serif;
    font-weight: 600;
    color: var(--color-text-main);
    text-decoration: underline;
  }

  &-Address {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;

    span {
      text-transform: uppercase;
    }
  }

  &-Map {
    width: 100%;
    padding: 16px 14px;
    background-color: var(--color-text-main3);

    @media (min-width: $screen-l) {
      padding: 46px 44px 44px 53px;
    }

    @media (min-width: $screen-xl) {
      max-width: 816px;
      width: 100%;
      height: 530px;
    }
  }

  &-MapMap {
    width: 100%;
  }
}
</style>
